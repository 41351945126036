<template>
  <div>
    <h1 class="mt-3">{{ $route.meta.heading }}</h1>
    <div class="row">
      <tool-square
        v-for="(tool, index) in tools"
        :key="index"
        :name="tool.title"
        :image="tool.image"
        :link="tool.url"
        :icon="tool.icon"
        :disabled="!$user.hasPermission(tool.permission)"
      />
    </div>
  </div>
</template>

<script>
import ToolSquare from "@/components/ToolSquare";

export default {
  name: "ToolPage",

  components: {
    ToolSquare,
  },

  data() {
    return {
      tools: [],
    };
  },

  methods: {
    getTools() {
      this.axios.post("/api/v1/tools/get", {
        group: this.$route.name
      }).then(({ data }) => {
        this.tools = data;
      });
    },
  },

  mounted() {
    this.getTools();
  },
};
</script>
